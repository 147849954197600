<template>
   <div class="container">
    <div class="container-left">
      <div class="org-title">我的组织</div>
      <div class="tree-wrap">
        <a-tree
          v-if="treeData.length>0"
          class="draggable-tree"
          :tree-data="treeData"
           @select="onSelect"
           :defaultExpandAll="true"
         />
      </div>
    </div>
    <div class="container-right">
      <div style="font-size: 18px;color:#333;font-weight:600">成员管理</div>
      <div style="margin:15px 0 20px">
         <a-input v-model="fullName" allowClear placeholder="请输入姓名" style="width:180px;margin-right:15px" />
         <a-input v-model="email" allowClear placeholder="请输入邮箱" style="width:180px;margin-right:15px" />

         <!-- <a-input v-model="jobNumber" allowClear placeholder="请输入工号" style="width:150px;margin-right:15px" /> -->
          <a-select placeholder="职务" allowClear v-model="userRole" style="width: 120px;margin-right:15px">
            <a-select-option :value="item.value" v-for="(item,index) in userRoleArr" :key="index">
              {{item.label}}
            </a-select-option>
          </a-select>
          <!-- <a-select placeholder="工作性质" allowClear v-model="jobCharacter" style="width: 120px;margin-right:15px">
            <a-select-option :value="item.value" v-for="(item,index) in jobCharacterArr" :key="index">
              {{item.label}}
            </a-select-option>
          </a-select> -->
           <!-- <a-select placeholder="账号状态" allowClear v-model="accountStatus" style="width: 120px;margin-right:15px">
            <a-select-option :value="item.value" v-for="(item,index) in accountStatusArr" :key="index">
              {{item.label}}
            </a-select-option>
          </a-select> -->
           <!-- <a-range-picker :value="rangeTime" @change="onChange" allowClear style="margin-right:15px" /> -->
           <a-button style="margin-right:15px" type="primary"  @click="searchAccount">搜索</a-button>
            <a-button  @click="resetAccount">重置</a-button>

      </div>
       <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
         @change="handleTableChange"
        :scroll="{ y: 600 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
       <template slot="accountStatus" slot-scope="accountStatus">
          <span> {{ initTabsList(accountStatus,accountStatusArr)[0].label }} </span>
        </template>
         <template slot="shiftTime" slot-scope="shiftTime,record">
          <span v-if="record.accountData && record.accountData.isLeave">休息</span>
          <span v-if="!(record.accountData && record.accountData.isLeave) && shiftTime && shiftTime.length>0">  
            {{$moment(shiftTime[0].offTime,'HH:mm').diff($moment(shiftTime[0].onTime,'HH:mm'),'h')<= 20?`${shiftTime[0].onTime} - ${shiftTime[0].offTime}`:'休息'}}
          </span>
        </template>
       <template slot="undertakeNewStu" slot-scope="undertakeNewStu,record">
              <a-switch :checked="undertakeNewStu==='ON'" size="small" @click="(checked)=>changeSwitch(checked,record)" :disabled="disableSwitch"  />
        </template>
      <template slot="operation" slot-scope="operation, record">
         <span class="operation" @click="hrefLimit(record)" style="margin-right:5px">每月限额</span>
        <span class="operation" @click="hrefStudent(record)">学生</span>
      </template>
      </a-table>
    </div>
   </div>
</template>

<script>
import storage from 'store';
import {getTreeList,getTreeAccount,changeUnderTake} from '@/api/headTeacher';

export default {
  name:'organization',
  data(){
    return{
      userInfo:storage.get('userInfo'),
      disableSwitch:true,
      checkSwitch:true,
      rangeTime:[],
      email:'',
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '姓名',
          dataIndex: 'fullName',
          key: 'fullName',
        },
         {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
         
        },
        // {
        //   title: '工号',
        //   dataIndex: 'orderTags',
        //   key: 'orderTags',
        //   width:100
        // },
        {
          title: '职务',
          dataIndex: 'roleString',
          key: 'roleString',
        },
        {
          title: '组织',
          dataIndex: 'departmentString',
          key: 'departmentString',
        },
         {
          title: '工作时间(北京)',
          dataIndex: 'accountData.shiftTime',
          key: 'shiftTime',
          scopedSlots: { customRender: 'shiftTime' },
        },
         {
          title: '承接新生',
          dataIndex: 'accountData.undertakeNewStu',
          key: 'undertakeNewStu',
          scopedSlots: { customRender: 'undertakeNewStu' },
        },
        // {
        //   title: '工作性质',
        //   dataIndex: 'approveStatus',
        //   key: 'approveStatus',
        //   scopedSlots: { customRender: 'approveStatus' },
        // },
        // {
        //   title: '入职日期',
        //   dataIndex: 'dateTime',
        //   key:'dateTime',
        //   width:150
        // },
        // {
        //   title: '账号状态',
        //   dataIndex: 'accountStatus',
        //   scopedSlots: { customRender: 'accountStatus' },
        //   width:100
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
     current: 0,
      defaultExpandAll:true,
      tableData:[],
      treeData:[],
      fullName:undefined,
      jobNumber:undefined,//工号
      userRole:undefined,
      jobCharacterArr:[
        {label:'全职',value:'FULL_TIME_JOB'},
        {label:'兼职',value:'PART_TIME_JOB'},
      ],
      jobCharacter:undefined,
      startDate:undefined,
      endDate:undefined,
      accountStatus:undefined,
      accountStatusArr:[
        {label:'正常',value:'NORMAL'},
        {label:'禁用',value:'LOCKED'},
      ],
      userRoleArr:[
        {label:'班主任',value:'CLASS_ADMIN'},
        {label:'小悟空',value:'X_WUKONG'},
        {label:'班主任主管',value:'HEAD_TEACHER_SUPER'},
        {label:'班主任组长',value:'HEAD_TEACHER_LEADER'},
        {label:'教务',value:'TEACHING_STAFF'},
        {label:'教务主管',value:'TEACHING_STAFF_ADMIN'},
      ],
      pageNum:1,
      pageSize:10,
      deptId:''
    }
  },
  methods:{
    changeSwitch(checked,record){
    
      let params={
        accountId:record.uuid,
        undertakeNewStu:checked?'ON':'OFF'
      }
      changeUnderTake(params).then(res=>{
        this.$message.success('修改状态成功')
          this.getTreeAccount()
      })
    },
    hrefLimit(record){
      this.$router.push({path:'/administration/monthlyLimit',query:{classAdminId:record.uuid}})
    },
    hrefStudent(record){
      this.$router.push({name: 'myStudent',params: { uuid:record.uuid,name:record.fullName }})

    },
    onChange(date,dateString){
      this.rangeTime=date;
      this.startDate=dateString[0];
      this.endDate=dateString[1];
    },
    resetAccount(){
      this.fullName=undefined;
      this.email=undefined;
      this.jobNumber=undefined,//工号
      this.userRole=undefined;
      this.jobCharacter=undefined;
      this.startDate=undefined;
      this.endDate=undefined;
      this.accountStatus=undefined;
      this.rangeTime=[];
    },
    searchAccount(){
     
       this.pageNum=1
      this.getTreeAccount()
    },
    onSelect(arr){
      this.deptId=arr[0]
      this.getTreeAccount()
    },
    getTreeList(){
      getTreeList().then(res=>{
        this.treeData=res.data
       
      })
    },
     handleTableChange(data){
     this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.getTreeAccount();
   },
    getTreeAccount(){
      let params={
        fullName:this.fullName,
        email:this.email,
        jobNumber:this.jobNumber,
        userRole:this.userRole,
        jobCharacter:this.jobCharacter,
        startDate:this.startDate,
        endDate:this.endDate,
        accountStatus:'NORMAL',
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        deptId:this.deptId
      }
      getTreeAccount(params).then(res=>{
        this.tableData=res.data.content
         this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
         this.initPagination.current =res.data.currentPage;
      })
    },
     initTabsList(value, data) {
      return data.filter((i) => i.value === value);
    },
  },
  mounted(){
     if(this.userInfo.leader){
          this.disableSwitch=false
      }
    this.getTreeList()
     this.getTreeAccount()
  }
}
</script>

<style lang="less" scoped>
.container{
  display:flex;
  background-color :#fff;
  border-radius:30px;
  height:90vh;
}
.container-left{
  
   height: 100%;
   border-right: 1px solid #ddd;
}
 
.container-middle{
  flex:1 
}
.org-title{
  font-size: 18px;
  color:#333;
  padding:15px;
  font-weight:600
}
.tree-wrap{
  padding:15px;
  width: 320px;
  height: 80vh;
  overflow-y: scroll;
}
.container-right{
  padding:15px;
}
  .operation {
    color: #43d186;
    &:hover {
      cursor: pointer;
    }
  }
</style>